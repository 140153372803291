'use strict';

angular.module('enervexSalesappApp').factory('AccountMembershipRequestComment', function ($resource) {
	return $resource('/api/account-membership-requests/:accountMembershipRequestId/comments/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});




